@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

.logo {
    font-family: Commissioner, sans-serif;
}

.logo_mini {
    width: 32px;
    padding: 0;
    margin: 0 auto;
    background-size: cover;
    overflow: hidden;
}

.ant-layout-content {
    /*background: #f5f5f5 !important;*/
}

.ant-list-item:hover {
    box-shadow: 0 0 4px #bdbdbd;
    background: #e0e0e0;
}

h4 {
    margin: 0;
}


.ProcessProjectInvite .ant-transfer-list {
    width: 50%;
}

.ProcessProjectInvite .ant-list-item-meta {
    display: flex;
}

.spinner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.23);
}

.taskNumber {
    color: #adadad;
    padding: 3px;
}

.taskDeadline {
    position: absolute;
    top: 50px;
    left: 0;
    color: #adadad;
}

.profile-form {
    max-width: 500px;
}

.profile-form .ant-input {
    cursor: default;
}

.projectBreadcrumbLink ol {
    flex-wrap: nowrap;
}

.projectBreadcrumbLink ol:last-child {
    overflow: hidden;
    max-height: 22px;
    text-overflow: ellipsis;
}

.responsibleAvatar {
    
}

/* Блок изменения аватара */
.change-avatar-btn {
    color: cornflowerblue;
    cursor: pointer;
}

.avatar-editor-contianer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ant-upload-list-item-container {
    margin-inline: 0 !important;
}

.ant-upload-list-item-actions button {
    display: none;
}

.avatar-editor-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
}

/* Конец блока изменения аватара */
/* Блок профиля другого пользователя */
.other-profile__avatar-section {
    margin-bottom: 30px;
}

.other-profile__name {
    margin-left: 40px;
}

.other-profile .ant-divider {
    margin: 10px 0;
}

.other-profile {
    max-width: 100%;
}

.other-profile__info {
    padding-top: 20px;
    max-width: 500px;
    font-size: 15px;
}

.other-profile__info-item {
    margin-bottom: 10px;
}

.other-profile__info-item p:first-child {
    color: #001529;
    font-weight: 500;
    margin-bottom: 5px;
}

.other-profile__info-item p:last-child {
    box-sizing: border-box;
    min-height: 35px;
    border-radius: 5px;
    padding: 5px 10px;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid #d9d9d9;
    box-shadow: none;
}

.other-profile__info-item.about p:last-child {
    min-height: 100px;
}

.ant-btn-primary.red {
    background-color: #db7070;
}

.ant-btn-primary.red:hover {
    background-color: #cb1d1d;
}

.teamMenuItem > .ant-menu-submenu-title {
    padding-left: 10px !important;
    color: #ffffff8f;
}

.ant-page-header .ant-page-header-heading-left{
    margin-inline-start: 20px;
}

.card {
    height: 100%;
    overflow: hidden;
}

.card > .ant-card-head {
    min-height: 40px
}

.card > .ant-card-body {
    padding: 0 10px 0 0;
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #d6d9de;
        /*border-top: 3px solid white;*/
        /*border-bottom: 3px solid white;*/
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1677ff;
        border-radius: 20px;
    }

    /* для FireFox */
    scrollbar-color: #d6d9de #1677ff; /* Цвет скролла, цвет ползунка */
    scrollbar-width: thin; /* Толщина скролла */
}

.card > .ant-card-body:hover {
    cursor: pointer;
    overflow-y: auto;
    padding-right: 8px;
}

@media (max-width: 1280px) {
    .card > .ant-card-body {
        cursor: pointer;
        overflow-y: auto;
        padding-right: 8px;
    }
}

.tariffsTableColumn {
    border-radius: 8px;
    margin: 40px 5px 20px;
    padding: 20px 0;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
}

.tariffsTableColumn:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}


.tariffsTableCardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 3px;
    margin: 0 -3px;
    line-height: 1.4;
}

.tariffsTableCardRow:hover {
    background-color: #e0e0e0;
}

.tariffsTableCardRow span {
    margin-left: 4px;
    font-size: 12px;
}

@media (max-width: 1440px) {
    .tariffsTableCardRow {
        flex-direction: column;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 10px 0;
    }
}

.tariffsTableCardButton {
    color: #fff;
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.tariffsTableCardRowModal {
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
}

.paymentAccountField {
    max-width: 250px;
    flex-basis: 40%;
}

.paymentAccountCell {
    flex-basis: 20%;
    display: flex;
    justify-content: center;
}

.ant-modal-content {
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #d6d9de;
        /*border-top: 3px solid white;*/
        /*border-bottom: 3px solid white;*/
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1677ff;
        border-radius: 20px;
    }

    /* для FireFox */
    scrollbar-color: #d6d9de #1677ff; /* Цвет скролла, цвет ползунка */
    scrollbar-width: thin; /* Толщина скролла */
}
/*Стили для slick slider, чтобы слайдер занимал всю доступную высоту*/
.generalBoardSlider,.slick-track,.slick-list{
    height: 100%;
}

div.slick-slide>div{
        height: 100%;
}

@keyframes flicker1 {
    0%, 19.9%, 22%, 62.9%, 64%, 64.9%, 70%, 100% {
        box-shadow: none;
    }
    20%, 21.9%, 63%, 63.9%, 65%, 69.9% {
        box-shadow: inset rgba(255, 255, 255, 0.48) 0 0 10px 3px;
    }
}

@keyframes flicker {
    0%, 49.4%, 50%, 100% {
        opacity: 0.8;
    }
    49.5%, 49.9% {
        opacity: 0.3;
    }
}

.ant-drawer-header-title {
    flex-direction: row-reverse;/*Чтобы closeIcon в drawer был справа*/
    >.ant-drawer-close{
        margin: 0;
    }
}